import { Alert, Button, Divider, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomIcon from "../../components/icons/CustomIcon";
import { updateLoadingPage } from "../../ui/uiSlice";
import { transformErrorObjectToText } from "../../utils/common";
import requests from "../../utils/requests";
import { showNotification } from "../notifications/notificationsSlice";
import { S } from "./Home";

const Card = ({children}) => (
    <Paper
        elevation={3}
        sx={{
            margin: "auto",
            mt: "-12vh",
            mb: 4,
            px: 6,
            py: 4,
            maxWidth: "550px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        }}
    >
        {children}
    </Paper>
)

const ContactForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { register, handleSubmit } = useForm()
    const [submitted, setSubmitted] = useState(false)

    const onSubmit = async (data) => {
        try  {
            dispatch(updateLoadingPage(true))
            await requests.sendContactInfo(data)
            setSubmitted(true)
        } catch (error) {
            dispatch(showNotification({
                type: "error",
                body: transformErrorObjectToText(error)
            }))
        } finally {
            dispatch(updateLoadingPage(false))
        }
    };

    return (
        <S.Home>
            <S.BackgroundWrapper>
                <S.Background>
                    <S.Logo src="/logo.png" alt="" />
                    <S.Heading>Request a Domain Forensic Report</S.Heading>
                    <S.SubHeading>
                        Welcome to our platform, for security reasons users are
                        invited onto the platform by the admin, to apply to use
                        the Domain Forensic Platform, fill our the contact form
                        below and our colleagues will contact you.
                    </S.SubHeading>
                </S.Background>
                <S.Triangle />
            </S.BackgroundWrapper>
            {submitted ? (
                <Card>
                    <Typography
                        variant="h5"
                        pb={1}
                        textAlign="center"
                        fontWeight={700}
                        color="#01579b"
                    >
                        Thank you!
                    </Typography>
                    <Divider orientation="horizontal" />
                    <Alert severity="success" color="info">Your contact information is sent to us. We'll get in touch soon.</Alert>
                    <Button sx={{ mt: 2 }} onClick={() => history.push('/')}>
                        <CustomIcon icon="chevUp" color="primary" sx={{ transform: 'rotate(-90deg)' }} />
                        Back to home
                    </Button>
                </Card>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <Typography
                            variant="h5"
                            pb={1}
                            textAlign="center"
                            fontWeight={700}
                            color="#5e5e5e"
                        >
                            Contact form
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            {...register("name")}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Position"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...register("position")}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Business"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...register("business")}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Contact number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...register("contactNumber")}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            type="email"
                            {...register("email")}
                        />
                        <Button variant="contained" type="submit">Submit</Button>
                    </Card>
                </form>
            )}
        </S.Home>
    );
};

export default ContactForm;
