import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import ConfirmModal from "../../components/ConfirmModal"
import CustomIcon from '../../components/icons/CustomIcon'
import { updateLoadingPage } from "../../ui/uiSlice"
import requests from "../../utils/requests"
import { validateEmail } from "../../utils/validations"
import { showNotification } from "../notifications"
import { showErrorNotification } from "../notifications/notificationsSlice"

const AddMemberModal = ({
    organizationId,
    refetchData,
}: {
    organizationId: number
    refetchData: () => Promise<void>
}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')

    const isValidEmail = validateEmail(email)

    const handleClose = () => setOpen(false)
    const addMember = async () => {
        try {
            dispatch(updateLoadingPage(true))
            await requests.addMember(organizationId, email)
            refetchData()
            setEmail('')
            handleClose()
            dispatch(showNotification({
                body: 'Add member successfully!',
                type: 'success',
            }))
        } catch (err) {
            dispatch(showErrorNotification(err))
        } finally {
            dispatch(updateLoadingPage(false))
        }
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                startIcon={<CustomIcon icon="plus" sx={{ color: 'white', fontSize: '16px' }} />}
                sx={{ fontSize: '10px' }}
                onClick={() => setOpen(true)}
            >
                Add new member
            </Button>
            <ConfirmModal
                title="Add new member"
                body={
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ width: 400 }}
                        error={!!email && !isValidEmail}
                        helperText={!!email && !isValidEmail && 'Invalid email'}
                    />
                }
                open={open}
                onClose={handleClose}
                onConfirm={addMember}
                disableSubmit={!isValidEmail}
            />
        </>
    )
}

export default AddMemberModal
