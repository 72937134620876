import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import Get from "../../components/Get";
import { DomainReportListResponse } from "./types";
import { formatDateTime } from "../../utils/datetime";

const ReportList = () => (
    <Get url={'/reports/domain-forensics/'}>
        {({ data }: { data: DomainReportListResponse }) => (
            <>
                <br/>
                { (data?.count || 0) === 0 && <p>No reports found</p> }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Domain</th>
                        <th>Generated on</th>
                    </tr>
                    </thead>

                    <tbody>
                    { data?.results?.map?.(report => (
                        <tr>
                            <td>
                                <Link to={`/reports/domain-forensics/${report.uuid}/`}>{report.uuid}</Link>
                            </td>
                            <td>{report.domain}</td>
                            <td>{formatDateTime(report.created)}</td>
                        </tr>
                    )) }
                    </tbody>
                </Table>

            </>
        )}
    </Get>
)

export default ReportList
