import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import useFormState from "../../hooks/useFormState";

const createUrl = (url: string): string => {
    const search = new URLSearchParams({ url })
    return `/reports/domain-forensics/?${search}`
}

const GenerateReportForm = () => {
    const [url, setUrl] = useFormState('')
    const history = useHistory()
    const link = createUrl(url)
    const onSubmit = (e) => {
        e.preventDefault()
        if (url) {
            history.push(link)
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Domain</Form.Label>
                <Form.Control
                    placeholder="digitpol.com"
                    value={url}
                    onChange={setUrl}
                />
            </Form.Group>

            <Button
                style={{backgroundColor: '#0062cc'}}
                disabled={!url}
                onClick={() => history.push(link)}
            >
                <b>Generate Report</b>
            </Button>
        </Form>
    )
}

export default GenerateReportForm
