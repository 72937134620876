import styled from "@emotion/styled";
import React, { createRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import auth from "../../utils/auth";
import { loginAction } from "../auth/authSlice";

const S = {
    Wrapper: styled.div`
        z-index: 99998;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        background-color: rgba(0, 0, 0, 0.34);
    `,
    CenterForm: styled.div`
        z-index: 99999;
        margin: auto;
    `,
};

const CreateAccount = ({
    isResettingPassword,
}: {
    isResettingPassword?: boolean;
}) => {
    const dispatch = useDispatch();
    const searchParam = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const widgetRef = createRef();

    const close = () => {};

    const login = (event: any) => {
        const jwt = event.detail;
        auth.storeTokens(jwt);
        dispatch(loginAction());
        history.push("/account");
    };

    useLayoutEffect(() => {
        // @ts-ignore
        widgetRef?.current?.addEventListener?.("callback", login);
    });

    return (
        <S.Wrapper onClick={close}>
            <S.CenterForm onClick={(e: any) => e?.stopPropagation?.()}>
                {/* @ts-ignore */}
                <digitpol-auth-widget
                    ref={widgetRef}
                    sha={searchParam.get('sha')}
                    isResettingPassword={isResettingPassword}
                    disableSignUp="true"
                />
            </S.CenterForm>
        </S.Wrapper>
    );
};

export default CreateAccount;
