import { useState } from 'react'
import { Button } from "react-bootstrap";
import LoginModal from "./LoginModal";
import {useHistory} from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "./authSlice";

interface LoginButtonProps {
    text?: string
    redirect?: boolean
}

const LoginButton = ({ text = "Login", redirect = false }: LoginButtonProps) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)

    const onLogin = () => {
        dispatch(getCurrentUser())
        setShowModal(false)
        if (redirect) {
            history.push('/account/')
        }
    }

    if (showModal) {
        return <LoginModal onLogin={onLogin} onClose={() => setShowModal(false)} />
    }

    return (
        <>
            <Button onClick={() => setShowModal(true)}>{text}</Button>
        </>
    )
}

export default LoginButton
