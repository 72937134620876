import { createRef, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import auth from '../../utils/auth'
import env from '../../constants/env'

const S = {
    LoginWrapper: styled.div`
        z-index: 99998;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.65);
    `,
    CloseButton: styled.div`
        position: relative;
        z-index: 99999;
        margin-left: 400px;
        color: white;
        font-size: 24px;
        font-weight: 700;
        cursor: pointer;
    `,
    CenterForm: styled.div`
        z-index: 99999;
    `,
    ForgotPassword: styled.div`
        width: 100%;
        text-align: right;
        color: white;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
            color: rgb(32, 124, 202);
        }
    `
}

interface Props {
    onLogin?: () => void
    onClose?: () => void
}

const LoginModal = ({
    onLogin = () => {},
    onClose = () => {}
} : Props) => {
    const loginRef = createRef()
    const resetPasswordRef = createRef()
    const [isResettingPassword, setIsResettingPassword] = useState(false)

    const login = (event: any) => {
        const jwt = event.detail
        auth.storeTokens(jwt)
        onLogin()
    }

    useLayoutEffect(() => {
        // @ts-ignore
        loginRef?.current?.addEventListener?.('callback', login)
    })

    return (
        <S.LoginWrapper onClick={onClose}>
            <S.CloseButton>x</S.CloseButton>
            <S.CenterForm onClick={(e: any) => e?.stopPropagation?.()}>
                {isResettingPassword ? (
                    // @ts-ignore
                    <digitpol-auth-widget ref={resetPasswordRef} resetPasswordLink={`${env.appUrl}/reset-password`} />
                ) : (
                    // @ts-ignore
                    <digitpol-auth-widget ref={loginRef} disableSignUp="true" />
                )}
                <S.ForgotPassword onClick={() => setIsResettingPassword(!isResettingPassword)}>
                    {!isResettingPassword ? 'Forgot your password?' : 'Back to login'}
                </S.ForgotPassword>
            </S.CenterForm>
        </S.LoginWrapper>
    )
}

export default LoginModal
