import React from "react";
import { Route, Switch } from "react-router-dom";

import GetDomainReport from './GetDomainReport'
import CreateDomainReport from './CreateDomainReport'

const ReportView = () => (
    <Switch>
        <Route path="/reports/domain-forensics/:uuid/" component={GetDomainReport}/>
        <Route path="/reports/domain-forensics/" component={CreateDomainReport}/>
    </Switch>
)

export default ReportView
