import { useHistory } from "react-router-dom";
import {Container, Nav} from "react-bootstrap";
import styled from "styled-components";

import routes from "../../routes";
import { useSelector } from "react-redux";
import { selectUser } from "../auth/authSlice";

const S = {
    Logo: styled.img`
      height: 24px;
      margin-left: -6px;
      cursor: pointer;
    `,
    Background: styled.div`
      background-color: #e8e8e8;
      @media print {
        display: none;
      }
    `,
    Nav: styled(Nav)`
      display: flex;
      justify-content: space-between;
    `,
    LinksWrapper: styled.div`
      display: flex;
      padding-top: 3px;
    `,
}

const TopNav = () => {
    const history = useHistory()
    const user = useSelector(selectUser)

    return (
        <S.Background>
            <Container>
                <S.Nav activeKey={history.location.pathname}>
                    <Nav.Item>
                        <Nav.Link href="/">
                            <S.Logo src='https://storage.googleapis.com/digitpol-files/logo.png' alt=''/>
                        </Nav.Link>
                    </Nav.Item>
                    <S.LinksWrapper>
                        {
                            routes
                                .filter(route => route.inTopNav)
                                .filter(route => {
                                    if (route.organization) {
                                        return user?.isSuperuser
                                    }
                                    if (route.isAuth) {
                                        return !!user
                                    }
                                    return true
                                })
                                .map((route) => (
                                    <Nav.Item key={route.path}>
                                        <Nav.Link href={route.path}>{route.title}</Nav.Link>
                                    </Nav.Item>
                                ))
                        }
                    </S.LinksWrapper>
                </S.Nav>
            </Container>
        </S.Background>
    )
}

export default TopNav