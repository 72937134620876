import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
    title: string
    body: string | ReactNode
    open: boolean
    onClose: () => void
    onConfirm: () => void
    disableSubmit?: boolean
}

const ConfirmModal = ({ title, body, open, onClose, onConfirm, disableSubmit }: Props) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent style={{paddingTop: '10px'}}>
                {body}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} autoFocus disabled={disableSubmit}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal
