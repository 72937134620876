// @ts-ignore
import { Get as GetReactAxios } from 'react-axios'
import { ReactChild, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { transformErrorObjectToText } from "../utils/common"

/* eslint-disable react/require-default-props */

/**
 * Wrapper around react-axios to:
 * - Add typescript
 * - Add default loader
 * - Get .data from payload
 * - Change to returned value from args to one single object.
 */

interface ChildrenProps {
    data: any
    response: any
    error: string
    isLoading: boolean
    refetch: () => void
}

interface GetProps {
    url: string
    children: (props: ChildrenProps) => ReactChild
    Loader?: React.FC,
    debounce?: number
    interval?: number
    hideLoader?: boolean
    hideError?: boolean
    disableLoader?: boolean
    disableCapture?: boolean
    params?: Record<string, string | number>
}

const Refetcher = ({ interval, refetch }: { interval: number, refetch: () => void }) => {
    useEffect(() => {
        const intervalId = setTimeout(refetch, interval * 1000)
        return () => clearTimeout(intervalId)
    }, [refetch])
    return null
}

const Get = ({
                 children,
                 url,
                 params = {},
                 debounce = 0,
                 interval = null,
                 Loader,
                 hideLoader = false,
                 hideError = false,
                 disableLoader = false,
                 disableCapture = false,
             }: GetProps) => (
    <GetReactAxios url={url} params={params} debounce={debounce}>
        { (error: any, response: any, isLoading: boolean, refetch: () => void) => {
            const data = response && response.data

            if (error && !disableCapture) {
                return hideError ? null : (
                    <div style={{color: "rgb(211, 47, 47)", marginTop: 32}}>
                        {transformErrorObjectToText(error)}
                    </div>
                )
            }

            if ((isLoading && !data) && !disableLoader) {
                if (hideLoader) { return null }
                if (Loader) { return <Loader /> }
                return <Spinner animation="border" />
            }

            if (!data) {
                return null
            }

            return <>
                {interval && <Refetcher interval={interval} refetch={refetch}/> }
                {children({ data, error, response, isLoading, refetch })}
            </>
        }}
    </GetReactAxios>
)

export default Get
