import {
    Button,
    Container,
    Divider,
    InputBase,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import FullpageLoading from "../../components/FullpageLoading"
import CustomIcon from "../../components/icons/CustomIcon"
import useAsync from "../../hooks/useAsync"
import requests from "../../utils/requests"
import LoginGuard from "../auth/LoginGuard"
import { showNotification } from "../notifications"
import OrganizationRow from "../organization/OrganizationRow"

const Organizations = () => {
    const dispatch = useDispatch()
    const { value, status, execute: fetchList } = useAsync(requests.getOrganizationList)
    const [orgName, setOrgName] = useState('')
    const organizations = value?.results || []
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleCreateOrganization = async () => {
        try {
            setIsSubmitting(true)
            await requests.createOrganization(orgName)
            dispatch(showNotification({
                body: 'Create organization successfully!',
                type: 'success'
            }))
            setOrgName('')
            fetchList()
        } catch (err: any) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: err?.message || 'Something went wrong!',
                    type: 'error',
                },
            })
        } finally {
            setIsSubmitting(false)
        }
    }

    if (!value?.results && status === 'pending') {
        return <FullpageLoading open={true} />
    }

    return (
        <LoginGuard>
            <Container sx={{ py: 2 }}>
                <div className="flex-space-between">
                    <Typography variant="h5" color={grey[700]} mb={2}>
                        Organizations
                    </Typography>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}
                    >
                        <InputBase
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Organization name..."
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip title="Enter name then click here to create new organization">
                            <div>
                                <Button
                                    color="primary"
                                    startIcon={<CustomIcon icon="plus" color={orgName ? 'primary' : 'action'} />}
                                    disabled={!orgName || isSubmitting}
                                    onClick={handleCreateOrganization}
                                >
                                    Create
                                </Button>
                            </div>
                        </Tooltip>
                    </Paper>
                </div>

                <Paper elevation={3} sx={{ mt: 2 }}>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Members</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizations.map((organization) => (
                                    <OrganizationRow organization={organization} key={organization.id} fetchList={fetchList} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
        </LoginGuard>
    )
}

export default Organizations
