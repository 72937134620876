import styled from 'styled-components'
import config from '../styles'

const S = {
    Footer: styled.footer`
      font-size: ${config.fontSizes.small}px;
      color: ${config.colors.gray};
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @media screen {
          bottom: ${config.pdfPadding}px;
          left: ${config.pdfPadding}px;
          right: ${config.pdfPadding}px;
      }
      @media print {
        page-break-after: always;
      }
    `,
    Primary: styled.span`
      color: ${config.colors.primary}
    `,
}

export default () => (
    <S.Footer>
        Cyber Crime Investigation, Intelligence & Digital Forensics
        <br />
        <br />
        ©UrlForensics.com. All images and content are copyright of @DIGITPOL and cannot be used, replicated or reproduced without written permission. 2013 - 2023
    </S.Footer>
)