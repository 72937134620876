import React, { useContext } from 'react'
import { useDispatch } from "react-redux"
import ConfirmModal from '../../components/ConfirmModal'
import requests from "../../utils/requests"
import { showNotification } from "../notifications"
import { showErrorNotification } from "../notifications/notificationsSlice"

interface Props {
    open: boolean
    handleClose: () => void
    organizationId: number
    memberId: number
    refetchData?: () => Promise<void>

}

const DeleteMemberModal = ({
    open,
    handleClose,
    organizationId,
    memberId,
    refetchData,
}: Props) => {
    const dispatch = useDispatch()
    const removeMember = async () => {
        try {
            await requests.removeMember(organizationId, memberId)
            refetchData?.()
            handleClose()
            dispatch(showNotification({
                body: 'Removed member',
                type: 'success'
            }))
        } catch (err) {
            dispatch(showErrorNotification(err))
        }
        
    }

    return (
        <ConfirmModal
            title="Remove member"
            body="Are you sure to remove this member from organization?"
            onClose={handleClose}
            open={open}
            onConfirm={removeMember}
        />
    )
}

export default DeleteMemberModal
