import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import authSlice from './features/auth/authSlice'
import notificationSlice from './features/notifications/notificationsSlice'
import uiSlice from "./ui/uiSlice"

const rootReducer = combineReducers({
    auth: authSlice,
    notifications: notificationSlice,
    ui: uiSlice,
})

const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
export default store
