import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const uiSlice = createSlice({
    initialState: {
        isLoading: false
    },
    name: 'UISlice',
    reducers: {
        updateLoadingPage: (state, {payload}) => {
            state.isLoading = payload
        }
    }
})

// Selectors
export const loadingPageSelector = (state: RootState) => state.ui.isLoading

// Actions
export const { updateLoadingPage } = uiSlice.actions

export default uiSlice.reducer
