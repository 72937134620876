import React from 'react'
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Container, Typography
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import EditableTextField from "../../components/EditableTextField"
import CustomIcon from "../../components/icons/CustomIcon"
import useAsync from "../../hooks/useAsync"
import requests from "../../utils/requests"
import AddMemberModal from "../organization/AddMemberModal"
import RemoveMemberModal from "../organization/RemoveMemberModal"
import LoginGuard from "../auth/LoginGuard"


const OrganizationDetail = () => {
    const history = useHistory()
    const { id } = useParams<{id: string}>()
    const fetchOrganizationCb = useCallback(() => requests.getOrganization(id), [id])
    const { value: organization, execute: fetchData } = useAsync(fetchOrganizationCb)
    const [name, setName] = useState('')
    const [removeModal, setRemoveModal] = useState(false)
    const [removeId, setRemoveId] = useState(0)

    useEffect(() => {
        if (organization?.name) {
            setName(organization.name)
        }
    }, [organization?.name])

    const changeOrganizationName = async () => {
        await requests.updateOrganization(id || '', { name })
    }

    const showRemoveModal = async (id: number) => {
        setRemoveModal(true)
        setRemoveId(id)
    }

    return (
        organization && (
            <LoginGuard>
                <Container>
                    <Button onClick={() => history.push('/organization')} variant="outlined" sx={{ mt: 2 }}>
                        <CustomIcon icon="chevUp" sx={{ transform: 'rotate(-90deg)' }} color="primary" />
                        Back to organizations
                    </Button>
                    <Card sx={{ mt: 2, p: 2, overflow: 'auto' }}>
                        <Box sx={{ margin: 1, mb: 4 }}>
                            <Typography
                                variant="overline"
                                gutterBottom
                                component="h1"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Organization name
                            </Typography>
                            <EditableTextField
                                value={name}
                                onChange={(value) => setName(value)}
                                onSave={changeOrganizationName}
                            />
                        </Box>
                        <Box sx={{ margin: 1, mb: 4 }}>
                            <Typography
                                variant="overline"
                                gutterBottom
                                component="span"
                                sx={{ fontWeight: 'bold', mr: 2 }}
                            >
                                Members
                            </Typography>
                            <AddMemberModal organizationId={organization.id} refetchData={fetchData} />
                            <Box pt={2} />
                            {!organization.members.length && <Alert severity="info">No members available</Alert>}
                            {organization.members.map((member) => (
                                <Chip
                                    label={member.email}
                                    variant="outlined"
                                    onDelete={() => showRemoveModal(member.id)}
                                    color="primary"
                                    sx={{ mr: 1, mb: 1 }}
                                    key={member.email}
                                />
                            ))}
                            <RemoveMemberModal
                                open={removeModal}
                                handleClose={() => setRemoveModal(false)}
                                organizationId={organization.id}
                                memberId={removeId}
                                refetchData={fetchData}
                            />
                        </Box>
                    </Card>
                </Container>
            </LoginGuard>
        )
    )
}

export default OrganizationDetail
