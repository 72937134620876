import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authAxios, Response } from '../../utils/api'
import auth from '../../utils/auth'
import { RootState } from '../../store'
import { getRefreshToken, isTokenExpired } from '../../utils/jwt'
import { UserType } from './types'

export interface AuthState {
    readonly isLoadingAuth: boolean
    readonly isLoggedIn: boolean
    readonly user?: UserType
}

const initAuth = !!getRefreshToken()

const initAuthState: AuthState = {
    isLoadingAuth: true,
    isLoggedIn: initAuth,
    user: undefined,
}

export const getCurrentUser = createAsyncThunk(
    'getCurrentUser',
    async (params, thunkAPi) => {
        const refreshToken = getRefreshToken()
        if (!refreshToken || isTokenExpired(refreshToken)) {
            return thunkAPi.rejectWithValue('Refresh Token is expired')
        }
        return authAxios.get<UserType>('/users/current/')
    },
)

export const logout = createAsyncThunk(
    'LOGOUT_USER',
    auth.logout,
)

const setUser = (state: AuthState, action: PayloadAction<Response<UserType>>): AuthState => (
    !action?.payload.data?.id
        ? onUnauthenticated()
        : ({
            isLoadingAuth: false,
            isLoggedIn: true,
            user: action.payload.data,
        })
)

const onUnauthenticated = (): AuthState => {
    localStorage.clear()
    return ({
        isLoadingAuth: false,
        isLoggedIn: false,
        user: undefined,
    })
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initAuthState,
    reducers: {
        removeUser: () => onUnauthenticated(),
        login: (state) => ({
            ...state,
            isLoadingAuth: false,
            isLoggedIn: true,
        })
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action) => setUser(state, action))
        builder.addCase(getCurrentUser.rejected, () => onUnauthenticated())
        builder.addCase(logout.fulfilled, () => onUnauthenticated())
    },
})

export const selectUser = (state: RootState) => state.auth.user
export const selectIsAuthenticated = (state: RootState) => state.auth.isLoggedIn
export const selectIsLoadingAuth = (state: RootState) => state.auth.isLoadingAuth
export const removeCurrentUser = authSlice.actions.removeUser
export const loginAction = authSlice.actions.login

export default authSlice.reducer
