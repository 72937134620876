import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentUser, selectIsAuthenticated, selectUser } from "./authSlice";
import { getAccessToken } from "../../utils/jwt";
import { Redirect } from "react-router-dom";

const LoginGuard = ({ children }: { children: any }) => {
    const isAuthenticated = useSelector(selectIsAuthenticated)
    const dispatch = useDispatch()
    const user = useSelector(selectUser)


    useEffect(() => {
        let active = true
        
        if (!active) return

        if (!user && isAuthenticated) {
            dispatch(getCurrentUser())
        }

        return () => {
            active = false
        }
    }, [isAuthenticated])

    if (!isAuthenticated && !getAccessToken()) {
        return (
            <Redirect to="/" />
        )
    }

    return children
}

export default LoginGuard
