import { useState } from "react";

function useFormState(initialState: any) {
    const [value, set] = useState(initialState);

    function setState(event: any) {
        if (typeof event?.target?.value === 'string') {
            set(event?.target?.value)
        }
    }

    return [value, setState];
}

export default useFormState
