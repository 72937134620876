import Title from '../../components/Title'
import SubTitle from '../../components/SubTitle'
import Paragraph from '../../components/Paragraph'
import PageMeta from '../../components/PageMeta'
import Br from '../../components/Br'
import TextContainer from '../../components/TextContainer'

const PrivacyStatement = () => (
    <TextContainer>
        <Title>Privacy Policy URLForensics.com</Title>
        <PageMeta>Last updated 22<span style={{fontSize: 9}}>nd</span> of September 2021</PageMeta>

        <SubTitle>Who we are</SubTitle>
        <Paragraph>
            Our website address is: <a href='https://urlforensics.com/'>https://urlforensics.com/</a><Br/>
            This Website collects some Personal Data from its Users.<Br/>
            Owner and Data Controller<Br/>
            Digitpol BV, Boogschutterstraat 1 | 7324 AE Apeldoorn | Netherlands<Br/>
            Owner contact email: <a href='mailto:info@digitpol.com'>info@digitpol.com</a><Br/>
        </Paragraph>


        <SubTitle>
            Types of Data collected
        </SubTitle>

        <Paragraph>
            Among the types of Personal Data that this Website collects, by itself or through third
            parties, there are: Cookies, Usage Data, email address, various types of Data, general activity
            data, About Me, Data communicated while using the service and phone number.
            Complete details on each type of Personal Data collected are provided in the dedicated
            sections of this privacy policy or by specific explanation texts displayed prior to the Data
            collection.<Br/>
            Personal Data may be freely provided by the User, or, in case of Usage Data, collected
            automatically when using this Website.<Br/>
            Unless specified otherwise, all Data requested by this Website is mandatory and failure to
            provide this Data may make it impossible for this Website to provide its services. In cases
            where this Website specifically states that some Data is not mandatory, Users are free not to
            communicate this Data without consequences to the availability or the functioning of the
            Service.<Br/>
            Users who are uncertain about which Personal Data is mandatory are welcome to contact
            the Owner. Any use of Cookies – or of other tracking tools – by this Website or by the
            owners of third-party services used by this Website serves the purpose of providing the
            Service required by the User, in addition to any other purposes described in the present
            document and in the Cookie Policy, if available.<Br/>
            Users are responsible for any third-party Personal Data obtained, published or shared
            through this Website and confirm that they have the third party’s consent to provide the
            Data to the Owner.<Br/>
            Our database contains a URLForensics.com, when you are a registered user and you submit
            a request to us, we will keep object data such as the time you requested the data, the
            domain whois data, SPF data, DMARC and DNS data, we will also store your IP login logs, all
            of these can be deleted at the users request.<Br/>
            We do not keep unregistered users requests, those are deleted once the report is
            downloaded.
        </Paragraph>

        <SubTitle>
            Mode and place of processing the Data Methods of processing
        </SubTitle>

        <Paragraph>
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure,
            modification, or unauthorized destruction of the Data.<Br/>
            The Data processing is carried out using computers and/or IT enabled tools, following
            organizational procedures and modes strictly related to the purposes indicated. In addition
            to the Owner, in some cases, the Data may be accessible to certain types of persons in
            charge, involved with the operation of this Website (administration, sales, marketing, legal,
            system administration) or external parties (such as third- party technical service providers,
            mail carriers, hosting providers, IT companies, communications agencies) appointed, if
            necessary, as Data Processors by the Owner. The updated list of these parties may be
            requested from the Owner at any time.
        </Paragraph>

        <SubTitle>
            Legal basis of processing
        </SubTitle>
        <Paragraph>
            The Owner may process Personal Data relating to Users if one of the following applies:
            Users have given their consent for one or more specific purposes. Note: Under some
            legislation the Owner may be allowed to process Personal Data until the User objects to
            such processing (“opt- out”), without having to rely on consent or any other of the following
            legal bases. This, however, does not apply, whenever the processing of Personal Data is
            subject to European data protection law;<Br/>
            provision of Data is necessary for the performance of an agreement with the User and/or
            for any pre-contractual obligations thereof;<Br/>
            processing is necessary for compliance with a legal obligation to which the Owner is subject;
            processing is related to a task that is carried out in the public interest or in the exercise of
            official authority vested in the Owner;<Br/>
            processing is necessary for the purposes of the legitimate interests pursued by the Owner
            or by a third party.<Br/>
            In any case, the Owner will gladly help to clarify the specific legal basis that applies to the
            processing, and in particular whether the provision of Personal Data is a statutory or
            contractual requirement, or a requirement necessary to enter into a contract.
        </Paragraph>

        <SubTitle>
            Place
        </SubTitle>
        <Paragraph>
            The Data is processed at the Owner’s operating offices and in any other places where the
            parties involved in the processing are located.<Br/>
            Depending on the User’s location, data transfers may involve transferring the User’s Data to
            a country other than their own. To find out more about the place of processing of such
            transferred Data, Users can check the section containing details about the processing of
            Personal Data.<Br/>
            Users are also entitled to learn about the legal basis of Data transfers to a country outside
            the European Union or to any international organization governed by public international
            law or set up by two or more countries, such as the UN, and about the security measures
            taken by the Owner to safeguard their Data.<Br/>

            If any such transfer takes place, Users can find out more by checking the relevant sections
            of this document or inquire with the Owner using the information provided in the contact
            section.
        </Paragraph>

        <SubTitle>
            Retention time
        </SubTitle>

        <Paragraph>
            Personal Data shall be processed and stored for as long as required by the purpose they
            have been collected for.<Br/>
            Therefore:<Br/>
            Personal Data collected for purposes related to the performance of a contract between the
            Owner and the User shall be retained until such contract has been fully performed.
            Personal Data collected for the purposes of the Owner’s legitimate interests shall be
            retained as long as needed to fulfill such purposes. Users may find specific information
            regarding the legitimate interests pursued by the Owner within the relevant sections of this
            document or by contacting the Owner.<Br/>
            The Owner may be allowed to retain Personal Data for a longer period whenever the User
            has given consent to such processing, as long as such consent is not withdrawn.
            Furthermore, the Owner may be obliged to retain Personal Data for a longer period
            whenever required to do so for the performance of a legal obligation or upon order of an
            authority.<Br/>
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right to
            access, the right to erasure, the right to rectification and the right to data portability cannot
            be enforced after expiration of the retention period.
        </Paragraph>

        <SubTitle>
            The purposes of processing
        </SubTitle>

        <Paragraph>
            The Data concerning the User is collected to allow the Owner to provide its Services, as well
            as for the following purposes: Interaction with external social networks and platforms,
            Contacting the User, Analytics, Access to third-party accounts, Displaying content from
            external platforms, Data transfer outside the EU, Handling activity data, RSS feed
            management, Interaction with live chat platforms, User database management, SPAM
            protection and Content commenting.<Br/>
            Users can find further detailed information about such purposes of processing and about
            the specific Personal Data used for each purpose in the respective sections of this
            document.<Br/>
            Facebook permissions asked by this Website<Br/>
            This Website may ask for some Facebook permissions allowing it to perform actions with
            the User’s Facebook account and to retrieve information, including Personal Data, from it.
            This service allows this Website to connect with the User’s account on the Facebook social
            network, provided by Facebook Inc.<Br/>
            For more information about the following permissions, refer to the Facebook permissions
            documentation and to the Facebook privacy policy.<Br/>

            The permissions asked are the following:<Br/>
            Basic information<Br/>
            By default, this includes certain User’s Data such as id, name, picture, gender, and their
            locale. Certain connections of the User, such as the Friends, are also available. If the User
            has made more of their Data public, more information will be available.
        </Paragraph>

        <SubTitle>
            Activities
        </SubTitle>

        <Paragraph>
            Provides access to the User’s list of activities.<Br/>
            Detailed information on the processing of Personal Data<Br/>
            Personal Data is collected for the following purposes and using the following services:<Br/>
            Access to third-party accounts Analytics<Br/>
            Contacting the User<Br/>
            Content commenting<Br/>
        </Paragraph>

        <SubTitle>
            Data transfer outside the EU
        </SubTitle>

        <Paragraph>
            Displaying content from external platforms<Br/>
            Handling activity data<Br/>
            Interaction with external social networks and platforms Interaction with live chat platforms<Br/>
            RSS feed management<Br/>
            SPAM protection<Br/>
            User database management<Br/>
        </Paragraph>

        <SubTitle>
            The rights of Users
        </SubTitle>

        <Paragraph>
            Users may exercise certain rights regarding their Data processed by the Owner. In
            particular, Users have the right to do the following:<Br/>
            Withdraw their consent at any time. Users have the right to withdraw consent where they
            have previously given their consent to the processing of their Personal Data.<Br/>
            Object to processing of their Data. Users have the right to object to the processing of their
            Data if the processing is carried out on a legal basis other than consent. Further details are
            provided in the dedicated section below.<Br/>
            Access their Data. Users have the right to learn if Data is being processed by the Owner,
            obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data
            undergoing processing.<Br/>
            Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask
            for it to be updated or corrected.<Br/>
            Restrict the processing of their Data. Users have the right, under certain circumstances, to
            restrict the processing of their Data. In this case, the Owner will not process their Data for
            any purpose other than storing it.<Br/>
            Have their Personal Data deleted or otherwise removed. Users have the right, under certain
            circumstances, to obtain the erasure of their Data from the Owner.<Br/>

            Receive their Data and have it transferred to another controller. Users have the right to
            receive their Data in a structured, commonly used and machine readable format and, if
            technically feasible, to have it transmitted to another controller without any hindrance. This
            provision is applicable provided that the Data is processed by automated means and that
            the processing is based on the User’s consent, on a contract which the User is part of or on
            pre-contractual obligations thereof. Lodge a complaint. Users have the right to Bring a claim
            before their competent data protection authority.
        </Paragraph>

        <SubTitle>
            Details about the right to object to processing
        </SubTitle>

        <Paragraph>
            Where Personal Data is processed for a public interest, in the exercise of an official authority
            vested in the Owner or for the purposes of the legitimate interests pursued by the Owner,
            Users may object to such processing by providing a ground related to their particular
            situation to justify the objection.<Br/>
            Users must know that, however, should their Personal Data be processed for direct
            marketing purposes, they can object to that processing at any time without providing any
            justification. To learn, whether the<Br/>

            Owner is processing Personal Data for direct marketing purposes, Users may refer to the
            relevant sections of this document.<Br/>
            How to exercise these rights<Br/>
            Any requests to exercise User rights can be directed to the Owner through the contact
            details provided in this document. These requests can be exercised free of charge and will
            be addressed by the Owner as early as possible and always within one month.
        </Paragraph>

        <SubTitle>
            Cookie Policy
        </SubTitle>

        <Paragraph>
            This Website uses Cookies. To learn more and for a detailed cookie notice, the User may
            consult the Cookie Policy.<Br/>
            Additional information about Data collection and processing Legal action
            The User’s Personal Data may be used for legal purposes by the Owner in Court or in the
            stages leading to possible legal action arising from improper use of this Website or the
            related Services.<Br/>
            The User declares to be aware that the Owner may be required to reveal personal data
            upon request of public authorities.
        </Paragraph>

        <SubTitle>
            Additional information about User’s Personal Data
        </SubTitle>

        <Paragraph>
            In addition to the information contained in this privacy policy, this Website may provide the
            User with additional and contextual information concerning particular Services or the
            collection and processing of Personal Data upon request.
        </Paragraph>

        <SubTitle>
            System logs and maintenance
        </SubTitle>

        <Paragraph>
            For operation and maintenance purposes, this Website and any third-party services may
            collect files that record interaction with this Website (System logs) use other Personal Data
            (such as the IP Address) for this purpose.
        </Paragraph>

        <SubTitle>
            Information not contained in this policy
        </SubTitle>

        <Paragraph>
            More details concerning the collection or processing of Personal Data may be requested
            from the Owner at any time. Please see the contact information at the beginning of this
            document.<Br/>
            How “Do Not Track” requests are handled<Br/>
            This Website does not support “Do Not Track” requests.<Br/>
            To determine whether any of the third-party services it uses honor the “Do Not Track”
            requests, please read their privacy policies.
        </Paragraph>

        <SubTitle>
            Changes to this privacy policy
        </SubTitle>

        <Paragraph>
            The Owner reserves the right to make changes to this privacy policy at any time by giving
            notice to its Users on this page and possibly within this Website and/or – as far as
            technically and legally feasible –<Br/>

            sending a notice to Users via any contact information available to the Owner. It is strongly
            recommended to check this page often, referring to the date of the last modification listed
            at the bottom.<Br/>
            Should the changes affect processing activities performed on the basis of the User’s
            consent, the Owner shall collect new consent from the User, where required.
        </Paragraph>
    </TextContainer>
)

export default PrivacyStatement
