import {
    clearAuthTokens,
    getAccessToken,
    setAuthTokens,
    useAuthTokenInterceptor,
    refreshTokenIfNeeded,
} from './jwt'
import { authAxios, axios, getPlainAxios } from './api'
import env from "../constants/env";

// These request should not be send with our axios client that has
// the token interceptor enabled.
export const noAuthAxios = getPlainAxios(env.authApiUrl)

export interface Jwt {
    readonly refresh: string
    readonly access: string
}

export interface LoginInput {
    email: string
    password: string
    token?: string
}

const storeTokens = (jwt: Jwt) => setAuthTokens({
    access: jwt.access,
    refresh: jwt.refresh,
})

const requestRefresh = async (refresh: string): Promise<string> => {
    const resp = await noAuthAxios.post<Jwt>('/jwt/refresh/', { refresh })
    return resp.data.access
}

const login = async (params: LoginInput) => {
    const response = await noAuthAxios.post<Jwt>('/jwt/obtain/', params)
    if (response.data) { storeTokens(response.data) }
    return response
}

useAuthTokenInterceptor(axios, { requestRefresh })
useAuthTokenInterceptor(authAxios, { requestRefresh })

export default {
    getAccessToken,
    storeTokens,
    refreshToken: () => () => refreshTokenIfNeeded(requestRefresh),
    login,
    logout: clearAuthTokens,
}
