import styled from 'styled-components'
import isNil from 'lodash/isNil'
import { Table as BSTable } from 'react-bootstrap'
import { formatDateTime, isDate } from '../../../utils/datetime'

const S = {
    Td: styled.td`
      width: 50%;
    `,
    FileLink: styled.a`
      display: block;
      max-width: 350px;
      word-wrap: anywhere;
    `,
    ColWrapper: styled.div`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    `,
    Col: styled.div`
      padding: 24px;
      min-width: 500px;
      flex-grow: 1;
    `,
    Wrapper: styled.div`
      position: relative;
      background: white;
    `,
}

type FieldValue = string | number | Date | boolean

const boolParser = (v: any): string => ((v && v !== 'False' && v !== 'false') ? 'Yes' : 'No')
const dateTimeParser = (v: string): string => v && formatDateTime(v)
const toString = (v: any): string => `${v}`
const getParser = (value: FieldValue): (v: FieldValue) => string => {
    if (isDate(value)) { return dateTimeParser }
    if (typeof value === "boolean") { return boolParser }
    return toString
}
const parseFieldValue = (value: FieldValue): string => getParser(value)(value)

export interface Field {
    label: string
    value: FieldValue
}

export interface TableProps {
    fields: Field[]
    compact?: boolean
    filterEmptyFields?: boolean
    header?: string
    emptyDefault?: string
}

const hasValue = (v) => !isNil(v) && v !== ''

const Table = ({
                   fields,
                   emptyDefault = '-',
                   compact = false,
                   filterEmptyFields = false,
               }: TableProps) => {
    const TableComponent = compact
        ? ({ children }: { children: any }) => <BSTable size="sm">{children}</BSTable>
        : ({ children }: { children: any }) => <BSTable>{children}</BSTable>

    return (
        <S.Wrapper>
            <TableComponent>
                <tbody>
                { fields
                    .filter(({ label, value }) => !filterEmptyFields || hasValue(value))
                    .map(({ label, value }) => (
                        <tr key={label}>
                            <S.Td>{label}</S.Td>
                            <S.Td>
                                {
                                    hasValue(value)
                                        ? parseFieldValue(value)
                                        : emptyDefault
                                }
                            </S.Td>
                        </tr>
                    )) }
                </tbody>
            </TableComponent>
        </S.Wrapper>
    )
}

export default Table
