import styled from 'styled-components'
import config from '../styles'

const S = {
    PageHeader: styled.h1<{ primary?: boolean, fontSize?: number }>`
      max-width: 350px;
      margin-top: 6px;
      height: 80px;
      word-wrap: anywhere;
      color: ${({ primary }) => (primary ? config.colors.primary : config.colors.gray)};
      font-weight: bold;
      font-size: ${({ fontSize }) => fontSize ?? config.fontSizes.large}px;
    `,
}

export default S.PageHeader
