import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Redirect, useHistory } from "react-router-dom";

import Loader from "../../components/Loader";
import { DomainReportResponse } from "./types";
import api from "../../utils/api";

const loaderText = `We are preparing your report, this might take us a little while.`
const ReportLoader = () => <Loader text={loaderText} fillScreen />

const CreateDomainReport = () => {
    const history = useHistory()
    const [uuid, setUUID] = useState('');
    const [error, setError] = useState('');
    const search = new URLSearchParams(window.location.search)

    useEffect(() => {
        (async () => {
            try {
                const resp = await api.get<DomainReportResponse>(`/reports/domain-forensics/get_or_create/?${search}`)
                const uuid = resp?.data?.uuid
                if (uuid) {
                    setUUID(uuid)
                } else {
                    // @ts-ignore
                    if (resp?.error?.detail === "Authentication credentials were not provided.") {
                        history.push('/contact-form')
                    }
                    // @ts-ignore
                    setError(resp?.error?.detail || "Something went wrong")
                }
            } catch (e) {
                console.error(e)
            }
        })()
    }, [])

    if (error) {
        return (
            <div style={{color: "rgb(211, 47, 47)", margin: 32}}>
                {error}
            </div>
        )
    }

    return (
        <ErrorBoundary fallback={<p>Failed to connect to server</p>}>
            {
                uuid
                    ? <Redirect to={uuid} />
                    : <ReportLoader />
            }
        </ErrorBoundary>
    )
}

export default CreateDomainReport
