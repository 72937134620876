// Transform error object to text to pass into body of notification
export const transformErrorObjectToText = (error: any) => {
    if (error.response) {
        // Request made and server responded
        const message = error.response.data?.detail || error.response.data?.message || error.response.data

        if (typeof message === 'object') {
            return JSON.stringify(message)
        }
        return `${message}`
    }
    if (error.request) {
        // The request was made but no response was received
        return 'Something went wrong'
    }
    // Something happened in setting up the request that triggered an Error
    return `${error.message}`
}
