import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const S = {
    Page: styled.div`
      @media screen {
        height: 100%;
        background: #333638;
        max-width: 100vw;
        width: 100%;
        overflow-x: scroll;
      }
    `,
    ButtonWrapper: styled.div`
      bottom: 24px;
      right: 24px;
      position: fixed;
      z-index: 5;

      @media print {
        display: none
      }
    `
}

const ReportPage = ({ children }: { children: any }) =>
    <S.Page>
        <S.ButtonWrapper>
            <Button onClick={window.print}>
                Print / Download
            </Button>
        </S.ButtonWrapper>

        { children }
    </S.Page>

export default ReportPage
