import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const S = {
    FillSpace: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    `,
    FullScreen: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `,
    Spinner: styled(Spinner)`
      display: block;
      margin: auto;
      border-color: #23225e;
      border-right-color: transparent;
    `,
    Body: styled.p`
        margin-top: 12px;
        font-weight: bold;
        color: #aaaaaa;
        white-space: pre-line;
    `,
    Loader: styled.div`
      display: block;
      margin: auto;
      border-color: black;
      border-right-color: transparent;
    `
}

export default ({ fillScreen = false, text = "Loading..." } : { fillScreen?: boolean, text?: string}) => {
    const Wrapper = fillScreen
        ? S.FullScreen
        : S.FillSpace
    return (
        <Wrapper>
            <S.Loader>
                <S.Spinner animation="border" role="status" variant="light" />
                <S.Body>
                    {text}
                </S.Body>
            </S.Loader>
        </Wrapper>
    )
}
