import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../components/Header'
import auth from '../../utils/auth'
import { getCurrentUser } from './authSlice'
import Loader from "../../components/Loader";
import {useHistory} from "react-router-dom";

const LogoutView = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        auth.logout()
        dispatch(getCurrentUser())
        history.push('/')
    }, [])

    return (
        <div>
            <Loader text="Logging out" />
        </div>
    )
}

export default LogoutView
