import React from 'react'
import styled from '@emotion/styled'
import {
    Alert,
    Collapse, IconButton, TableCell, TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import { blue } from '@mui/material/colors'
import { Box } from '@mui/system'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import CustomIcon from '../../components/icons/CustomIcon'
import { DATETIME_FORMAT } from "../../utils/datetime"
import { Organization } from "./types"

const S = {
    MemberInfo: styled.div`
        display: inline-block;
        max-width: 150px;
        overflow: hidden;
        padding: 4px 8px;
        border: 1px solid ${blue[600]};
        border-radius: 4px;
        margin-right: 4px;
        font-size: 12px;
        background: white;
    `,
}

const OrganizationRow = ({
    organization,
    fetchList,
}: {
    organization: Organization
    fetchList: () => Promise<void>
}) => {
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <CustomIcon icon="chevUp" /> : <CustomIcon icon="chevDown" />}
                    </IconButton>
                </TableCell>
                <TableCell>{format(new Date(organization.created), DATETIME_FORMAT)}</TableCell>
                <TableCell>{organization.name}</TableCell>
                <TableCell>{organization.members.length}</TableCell>
                <TableCell align="right">
                    <Link to={`/organizations/${organization.id}`}>
                        <IconButton size="small">
                            <CustomIcon icon="edit" color="primary" sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow sx={{ background: '#ffffee' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="overline"
                                gutterBottom
                                component="span"
                                sx={{ fontWeight: 'bold', mr: 2 }}
                            >
                                Members
                            </Typography>
                            <Box pt={2} />
                            {!organization.members.length && (
                                <Alert severity="info">No members available</Alert>
                            )}
                            {organization.members.map((member) => (
                                <Tooltip title={member.email || ''} placement="bottom-start" key={member.id}>
                                    <S.MemberInfo key={member.id} className="text-cut">
                                        {member.email}
                                    </S.MemberInfo>
                                </Tooltip>
                            ))}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default OrganizationRow
