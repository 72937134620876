import styled from 'styled-components'
import styles from '../styles'

const S = {
    PdfPreview: styled.div`
      font-size: ${styles.fontSizes.default}px;
      background: ${styles.colors.previewBackground};
      width: 1000px;
      overflow: hidden;
      margin: auto;
    `,
}

export default ({ children }: { children: any }) => (
    <S.PdfPreview>
        {children}
    </S.PdfPreview>
)
