import React from "react";
import { Container } from "react-bootstrap";

import GenerateReportForm from "../reports/GenerateReportForm";
import ReportList from "../reports/ReportList";
import LoginGuard from "./LoginGuard";

const AccountView = () => (
    <LoginGuard>
        <Container>
            <ReportList/>
            <br/>
            <GenerateReportForm/>
        </Container>
    </LoginGuard>
)

export default AccountView
