import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { Button, Card, Container } from "react-bootstrap";
import { MdLock, MdFastForward, MdCheck } from "react-icons/md";

import GenerateReportForm from "../reports/GenerateReportForm";
import LoginButton from "../auth/LoginButton";
import {selectIsAuthenticated} from "../auth/authSlice";

export const S = {
    Home: styled.div`
      background-color: #fbffff;
      min-height: 80vh;
    `,
    Heading: styled.div`
      color: #fbffff;
      font-weight: bold;
      font-size: 30px;
      margin-top: 0;
      padding: 6px;
      margin-bottom: 12px;
      text-align: center;
    `,
    SubHeading: styled.div`
      margin: auto;
      max-width: 600px;
      color: #fbffff;
      font-size: 18px;
      padding: 6px;
      margin-top: 0;
      text-align: center;
    `,
    Logo: styled.img`
      margin: auto;
      display: block;
      height: 50px;
    `,
    BackgroundWrapper: styled.div`
      overflow: hidden;
    `,
    Background: styled.div`
      background-color: #0062cc;
      padding-bottom: 10vh;
    `,
    Triangle: styled.div`
      border-top: 5vw solid #0062cc;
      border-left: 100vw solid transparent;
    `,
    FormCard: styled(Card)`
      margin: auto;
      margin-top: -10vh;
      max-width: 600px;
    `,
    InfoWrapper: styled(Container)`
      margin-top: 5vh;
      margin-left: auto;
      margin-right: auto;
    `,
    UspWrapper: styled.div`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      max-width: 100vw;
    `,
    Usp: styled.div`
      display: flex;
      width: 33.33%;
      min-width: 250px;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
    `,
    UspIcon: styled.div`
      margin: auto;
      text-align: center;
      background-color: #0062cc;
      border-radius: 100%;
      padding: 9px;
      margin-bottom: 12px;
    `,
    UspHeading: styled.div`
      font-size: 24px;
      font-weight: bold;
      margin: auto;
      margin-top: 3px;
      text-align: center;
      margin-bottom: 12px;
    `,
    UspText: styled.div`
      margin: auto 24px;
      text-align: center;
    `,
    LoginSection: styled.div`
      background-color: #d5d5d5;
      padding: 24px;
    `,
    LoginText: styled.h3`
      font-size: 18px;
      color: white;
      margin: auto 0;
      padding: 0;
    `,
    LoginContainer: styled(Container)`
      display: flex;
      justify-content: space-between;
    `,
}

type UspData = { Icon: any, heading: string, text: string }

const Usp = ({ Icon, heading, text }: UspData) =>
    <S.Usp>
        <S.UspIcon>
            <Icon size={50} height={50} color="white" />
        </S.UspIcon>
        <S.UspHeading>{heading}</S.UspHeading>
        <S.UspText>{text}</S.UspText>
    </S.Usp>

const usps: UspData[] = [
    {
        Icon: MdLock,
        heading: 'DNS Reports',
        text: 'Obtain forensic evidence reports for litigation and investigations',
    },
    {
        Icon: MdCheck,
        heading: 'DNS Forensic Data',
        text: 'DNS settings, IP address, servers, hosting providers, DMARC, SPF, registrant data',
    },
    {
        Icon: MdFastForward,
        heading: 'Domain Fraud Investigation',
        text: (
            'The fraudulent domain’s creation date and email servers are a critical starting point.'
        ),
    },
]

const LoginOrGoToMe = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated)
    return isAuthenticated
        ? <Button as={Link} to="/account/">Go to profile</Button>
        : <LoginButton redirect />
}

const Home = () => (
    <S.Home>
        <S.BackgroundWrapper>
            <S.Background>
                <S.Logo src="/logo.png" alt="" />
                <S.Heading>Domain Forensic Investigation</S.Heading>
                <S.SubHeading>
                    URL Forensics, a toolset to investigate fraudulent or suspected website domains. Analysis of URL
                    data, identify IP address, hosting providers, email servers, domain creation date, registrant
                    information, DMARC and SPF records. A forensic toolset for investigation and incident response teams. Create reports and track changes on domains.
                </S.SubHeading>
            </S.Background>
            <S.Triangle/>
        </S.BackgroundWrapper>

        <S.FormCard>
            <Card.Body>
                <GenerateReportForm />
            </Card.Body>
        </S.FormCard>

        <S.InfoWrapper>
            <S.UspWrapper>
                { usps.map((props, index) => <Usp key={index} {...props} />) }
            </S.UspWrapper>
        </S.InfoWrapper>

        <S.LoginSection>
            <S.LoginContainer>
                <S.LoginText>Already have an account?</S.LoginText>
                <LoginOrGoToMe/>
            </S.LoginContainer>
        </S.LoginSection>
    </S.Home>
)

export default Home
