import Table, { Field, TableProps } from "./Table";
import PdfPage from "./PdfPage";
import { ErrorBoundary } from "@sentry/react";
import PageHeader from "./PageHeader";
import React from "react";

interface TablePageProps extends TableProps {
    pageHeaderText:  string
    errorFallback?:  React.ReactElement
}

const divideFieldsPerPage = (fields: Field[]): Field[][] => {
    const CHARS_IN_ROW = 50
    const ROWS_IN_PAGE = 30

    type Page = {rows: number, fields: Field[]}
    const store: Page[] = []

    const getPage = (): Page => {
        const lastPage = store[store.length - 1]
        if (lastPage && (lastPage.rows < ROWS_IN_PAGE)) {
            return lastPage
        }
        const nextPage = {
            rows: 0,
            fields: [],
        }
        store.push(nextPage)
        return nextPage
    }

    const setToPage = (field: Field) => {
        const fieldRows = Math.ceil(`${field.value}`.length / CHARS_IN_ROW) || 1
        const page = getPage()
        page.fields.push(field)
        page.rows = page.rows + fieldRows
    }

    fields.forEach(setToPage)
    return store.map(page => page.fields)
}


const TablePage = ({
                       fields,
                       pageHeaderText,
                       errorFallback=<p>Something went wrong</p>,
                       ...tableProps
                   }: TablePageProps) => {
    const fieldPerPage = divideFieldsPerPage(fields)
    const amountOfPages = fieldPerPage.length
    return (
        <ErrorBoundary fallback={errorFallback}>
            {
                fieldPerPage.map((fields, i) => (
                    <PdfPage key={i}>
                        <PageHeader>
                            {pageHeaderText}
                            {" "}
                            ({i + 1}/{amountOfPages})
                        </PageHeader>
                        <Table {...tableProps} fields={fields}/>
                    </PdfPage>
                ))
            }
        </ErrorBoundary>
    )
}

export default TablePage
