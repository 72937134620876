export const localeOptions = {
    hour12: false,
    timezone: 'Europe/Amsterdam',
}

const dateOptions = {
    ...localeOptions,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

const dateTimeOptions = {
    ...localeOptions,
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

export type DateTime = Date | string | number;

export const parseDateTimeString = (dateTime: string) => {
    // E.g: 2020-10-10T04:13:00 or 2020-10-10T04:13 or 2020-10-10T04:13:00.45623
    const noTimeZoneRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}){0,1}(\.\d+){0,1}$/
    if (noTimeZoneRegex.test(dateTime)) {
        // The dateTime return from server is UTC dateTime by convention, but the format likes 2020-10-10T04:13:00
        // The UTC ISO format for parseISO likes 2020-10-10T04:13:00+00:00. So concat suffix (+00:00) to follow ISO 8601 format
        const ISODatetime = new Date(Date.parse(`${dateTime}+00:00`))
        if (isDate(ISODatetime)) { return ISODatetime }
        throw Error(`dateTime ${dateTime} passed noTimeZoneRegex, but could not be converted into Date object`)
    }

    return new Date(dateTime)
}

export const isDate = (dateTime: any): dateTime is Date => !!dateTime && !!dateTime.getDate

const toDateSwitch = (dateTime: DateTime): Date | undefined => {
    if (isDate(dateTime)) {
        return dateTime
    }

    switch (typeof dateTime) {
    case 'number': return new Date(dateTime as number) || undefined
    case 'string': return parseDateTimeString(dateTime)
    case 'object': return isDate(dateTime) ? (dateTime as Date) : undefined
    default: return undefined
    }
}

export const toDate = (dateTime: DateTime): Date => {
    const value = toDateSwitch(dateTime)
    if (!isDate(value)) {
        throw Error(`toDate can't create date for value ${dateTime}`)
    }
    return value
}

// form format: 2021-03-03 15:01
export const formatDateTimeShort = (date?: DateTime): string | undefined => date && (
    new Date(toDate(date).getTime() - (toDate(date).getTimezoneOffset() * 60000))
        .toISOString()
        .replace('T', ' ')
        .substring(0, '2021-03-03 15:01'.length)
)

export const formatDate = (date?: DateTime): string | undefined => date && (
    toDate(date)
        // @ts-ignore
        .toLocaleString('en-US', dateOptions)
        .replace('Z', '')
)

export const formatDateTime = (date?: DateTime): string | undefined => date && (
    toDate(date)
        // @ts-ignore
        .toLocaleString('en-US', dateTimeOptions)
        .replace('Z', '')
)

export const nlToIsoDateTime = (date?: DateTime): string | undefined => date && (
    new Date(toDate(date).getTime() + (toDate(date).getTimezoneOffset() * 60000)).toISOString()
)

export const formatNlDateTime = (date?: DateTime): string | undefined => date && (
    new Date(toDate(date).getTime() - (toDate(date).getTimezoneOffset() * 60000))
        .toISOString()
        .substring(0, '2021-03-03T15:00'.length)
)

export const formatNlTime = (date?: DateTime): string | undefined => date && (
    new Date(toDate(date).getTime() - (toDate(date).getTimezoneOffset() * 60000))
        .toISOString()
        .substring('2021-03-03T'.length, '2021-03-03T15:00:00'.length)
)

export const getDateTimeInWords = (v: DateTime): string => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const date = toDate(v)
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${date.getUTCHours()}:${date.getMinutes()}h UTC`
}

export const DATETIME_FORMAT = 'yyyy/L/d HH:mm:ss'
