import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import TextContainer from '../../components/TextContainer'

const Contact = () => (
    <TextContainer>
        <Title>
            Contact Us
        </Title>

        <Paragraph>
            URL Forensics is a tool developed and owned by DIGITPOL therefore technical support is offered directly by DIGITPOL.
            <a href='https://digitpol.com/contact-us/'> https://digitpol.com/contact-us/</a>
        </Paragraph>

        <Paragraph>
            <b>By Phone: </b><br/>
            The main landline switch of DIGITPOL is +31558448040 available 0900h to 1700h Mon – Friday
            <br/>
            <br/>
            For out of office hours or <a href="/contact-form">contact via app</a>, Mobile: +31639220420
        </Paragraph>

        <Paragraph>
            <b>Via Email: </b><br/>
            You can use the contact form to submit your question or send us an email at info@digitpol.com
        </Paragraph>

        <Paragraph>
            <b>Address: </b><br/>
            Boogschutterstraat 1 - 43, 7324AE, Apeldoorn, The Netherlands
        </Paragraph>
    </TextContainer>
)

export default Contact
