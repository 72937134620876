import { Organization } from "../features/organization/types"
import { ListResponse } from "../types"
import { axios } from './api'

export default {
    getMyOrganization: async () => {
        const response = await axios.get('/organizations/my_organization/')
        return response.data
    },
    getOrganizationList: async (): Promise<ListResponse<Organization>> => {
        const response = await axios.get('/organizations/')
        return response.data
    },
    getOrganization: async (id?: string): Promise<Organization> => {
        const response = await axios.get(`/organizations/${id}/`)
        return response.data
    },
    createOrganization: async (name: string) => {
        await axios.post('/organizations/', { name, members: [] })
    },
    updateOrganization: async (id: string, data: {name?: string, alertEmail?: string}) => {
        await axios.patch(`/organizations/${id}/`, data)
    },
    addMember: async (organizationId: number, email: string) => {
        await axios.post(`/organizations/${organizationId}/add_member/`, { email })
    },
    removeMember: async (organizationId: number, memberId: number) => {
        await axios.post(`/organizations/${organizationId}/remove_member/`, { id: memberId })
    },
    sendContactInfo: async (data) => {
        await axios.post(`/contacts/`, data)
    }
}