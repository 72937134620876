import React from 'react'
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { withErrorBoundary } from "@sentry/react";
import { Helmet } from "react-helmet";
import styled from 'styled-components'

import './App.css'
import NotificationsContainer from './features/notifications/NotificationsContainer'
import routes from "./routes";
import Footer from "./features/pages/Footer";
import TopNav from "./features/pages/TopNav";
import FullpageLoading from "./components/FullpageLoading";
import CreateAccount from "./features/pages/CreateAccount";

const S = {
    App: styled.div`
      overflow-y: auto;
    `,
    TopNav: styled(Container)`
      @media print {
        display: none;
      }

      padding-top: 12px;
        padding-bottom: 12px;
    `,
    PageWrapper: styled.div`
      min-height: 100vh;
      max-width: 100vw;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    Page: styled.div`
        height: 100%;
    `,
}

const CreateComponent = ({ route, ...props }: { route }) => {
    const Component = withErrorBoundary(route.component, { fallback: <p>Something went wrong</p> })
    return (
        <S.PageWrapper>
            <Helmet>
                <title>Domain Forensics | {route.title}</title>
            </Helmet>

            <S.Page>
                { route.showTopNav && <TopNav /> }

                <Component {...props} />
            </S.Page>

            <Footer/>
        </S.PageWrapper>
    )
}

const App = () => (
    <S.App>
        <BrowserRouter>
            <NotificationsContainer />
            <FullpageLoading />

            <S.Page>
                <Switch>
                    { routes.map(route => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            key={route.path}
                        >
                            <CreateComponent route={route} />
                        </Route>
                    )) }
                    <Route path="/reset-password">
                        <CreateAccount isResettingPassword />
                    </Route>
                    <Route><Redirect to="/" /></Route>
                </Switch>
            </S.Page>
        </BrowserRouter>
    </S.App>
)

export default App
