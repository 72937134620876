import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import preval from 'preval.macro'
// @ts-ignore
import { AxiosProvider } from 'react-axios'
import 'bootstrap/dist/css/bootstrap.css'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { axios } from './utils/api'
import env from "./constants/env";

// store.dispatch(getCurrentUser())

console.log(preval`module.exports = new Date().toLocaleString();`)

Sentry.init({
    dsn: "https://a2d03b2870e34db1bc3e102c3a68c044@o534273.ingest.sentry.io/5935847",
    integrations: [new Integrations.BrowserTracing()],
    release: env.buildVersion,
    environment: env.environment,
    debug: env.debug,
    sampleRate: env.debug ? 0 : 1,
});

ReactDOM.render(
    <Provider store={store}>
        <AxiosProvider instance={axios}>
            <App />
        </AxiosProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
