import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from "react-redux"
import { loadingPageSelector } from "../ui/uiSlice"

const FullpageLoading = ({open}: {open?: boolean}) => {
    const isLoading = useSelector(loadingPageSelector)

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex:  1301 }}
            open={open || isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default FullpageLoading
