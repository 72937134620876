const colors = {
    primary: '#00437a',
    gray: '#5e5e5e',
    pdfBackground: 'white',
    previewBackground: '#333638',
}

const fontSizes = {
    small: 12,
    default: 18,
    large: 30,
}

export default {
    colors,
    fontSizes,
    pdfPadding: 48,
}
