import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import Br from "../../components/Br";
import TextContainer from "../../components/TextContainer";

const About = () => (
    <TextContainer>
            <Title>
                Forensic Investigation of Website Domains, IP & DNS
            </Title>

            <Paragraph>
                URL Forensics, a toolset to investigate fraudulent or suspected website domains, URL’s and analyse DNS data. The toolbox is provided to investigate websites, IP address, Hosting providers, email servers, domain creation date, registrant information, DMARC & SPF data.
                <Br/>
                <Br/>
                Fraudulent Domains are used in cybercrime, email fraud and online scams, the analysis of fraudulent domains is the first step in most cybercrime investigations, often the domain creation date and email servers are a critical starting point.
                <Br/>
                <Br/>
                A Domain investigation is used to obtain the IP address the email is sent from and it then verifies it with the ones mentioned in the SPF record. URL Forensics is a tool developed by DIGITPOL.
                <Br/>
                <Br/>
                <b>About Us</b>
                <Br/>
                Founded in 2013, DIGITPOL is an investigation agency specialised in cyber crime, digital forensics, fraud investigation, tracing and intelligence. DIGITPOL provides cybersecurity, digital forensics, fraud investigation, incident response, email security, and digital services related to governance, risk and transparency.
                <Br/>
                <Br/>
                DIGITPOL cooperates closely with Law Enforcement agencies and National Security Services and is headquartered in The Netherlands.
            </Paragraph>
    </TextContainer>
)

export default About
