import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { transformErrorObjectToText } from "../../utils/common"
import { NewNotification, Notification } from './types'

let nextId = 0
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [] as Notification[],
    },
    reducers: {
        showNotification(state, action: PayloadAction<NewNotification>) {
            state.notifications.push({
                id: ++nextId,
                type: 'info',
                ...action.payload,
            })
        },
        closeNotification(state, action: PayloadAction<number>) {
            state.notifications = state.notifications.filter(({ id }) => id !== action.payload)
        },
    },
})

type ErrorType = Record<string, unknown> | string | string[]

export const showErrorNotification = (error: ErrorType) => notificationsSlice.actions.showNotification({
    type: 'error',
    body: transformErrorObjectToText(error),
})

export const closeNotification = notificationsSlice.actions.closeNotification
export const showNotification = notificationsSlice.actions.showNotification
export default notificationsSlice.reducer
